import {Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {Itens, registerBuyPros} from "./types";
import {useUser} from "../../../providers/User";
import {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {ItemCard} from "../../Item";
import {cnpjMask, crfMask} from "../../../utils/formMasks";
import {DispensationMedicineRegister, DispensationRegister} from "../../../types/recipe";
import Button from "../../Button";
import {CircularProgress, SelectChangeEvent} from "@mui/material";
import Input from "../../Input";
import {SelectOptionItem} from "../../../types";
import SelectInput from "../../SelectInput";
import {BrazilianState} from "../../../types/ibge";
import {getBrazilianStates} from "../../../utils/brazilianStates";
import Swal from "sweetalert2";
import {api} from "../../../services";
import {toast} from "../../../utils/toast";

const RegisterBuy = ({isOpen, closeModalWithoutWarning, closeModal, code}: registerBuyPros) => {
  const {userData} = useUser();
  const [item, setItem] = useState<string>("");
  const [brazilianStates, setBrazilianStates] = useState<BrazilianState[]>([]);
  const [itensList, setItensList] = useState<Itens[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().required("*Campo obrigatório").email("Formato de email Inválido"),
    localCnpj: yup.string().required("*Campo obrigatório"),
    localName: yup.string().required("*Campo obrigatório"),
    pharmaceuticalCrf: yup.string().required("*Campo obrigatório"),
    pharmaceuticalCrfUf: yup.string().required("*Campo obrigatório"),
    pharmaceuticalName: yup.string().required("*Campo obrigatório"),
    purchaseDescription: yup.string(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: {errors},
  } = useForm<DispensationRegister>({resolver: yupResolver(schema)});

  const onSubmit = async (data: DispensationRegister) => {
    Swal.fire({
      title: "Realizar a queima da receita?",
      text: "Você não vai conseguir desfazer essa ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sim, queimar receita!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        registerBuy(data);
      }
    });
  }

  const registerBuy = async (data: DispensationRegister) => {
    if (itensList.length === 0) {
      return Swal.fire({
        position: "center",
        icon: "error",
        title: "Você precisa adicionar os itens comprados",
        showConfirmButton: false,
        timer: 1500,
      });
    }

    data.code = code;
    data.type = 1; 
    data.medicines = itensList.map(o => {
      let medicine: DispensationMedicineRegister = {description: o.nome};
      return medicine;
    });
    data.idPharmaceutical = userData.idProfessional;

    setIsLoading(true);
    try {
      const resp = await api.post("/receita/dispensation", data);

      if (resp.data === "Success") {
        setIsLoading(false);

        toast.fire({
          icon: "success",
          title: "Dispensação realizada!",
        });

        return closeModalWithoutWarning();
      }
    } catch (error: any) {
      setIsLoading(false);

      let message = error.response.data?.Erro
        ? error.response.data.Erro[0]
        : "Ocorreu um erro, tente novamente.";

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  const handleLocalCnpjInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setValue("localCnpj", cnpjMask(inputValue));
  };

  const handleCrfInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setValue("pharmaceuticalCrf", crfMask(inputValue));
  };

  const handleSelectBrazilianState = async (event: SelectChangeEvent<any>) => {
    const brazilianState = event.target.value;
    setValue("pharmaceuticalCrfUf", brazilianState);
  };

  const handleNewItem = (item: string) => {
    const newItem = {nome: item, id: uuidv4()};

    if (newItem.nome !== "") {
      setItensList([...itensList, newItem]);
      setItem("");
    }
  };

  const removeEnterKeyEvent = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleNewItem(item);
    } else {
      setItem(item);
    }
  };

  useEffect(() => {
    getBrazilianStates(setBrazilianStates);
    reset();
  }, [userData, reset]);

  return (
    <Modal show={isOpen} onHide={closeModal} keyboard={false} centered>
      <div className="modal fade show" id="modalCompra" style={{display: "block"}} aria-modal="true" role="dialog">
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-centered modal-dialog-scrollable" role="document">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="modal-content aos-init"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-once
          >
            <div className="modal-header">
              <h5 className="modal-title">Registrar Compra</h5>
              <button type="button" className="btn btn-sm rounded-pill btn-outline-primary icon" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}>
                <i className="icon-mid bi bi-x"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      CNPJ Local de Compra
                      <span className="text-danger">*</span>
                    </label>
                    <div className="form-floating">
                      <Input
                        type="cnpj"
                        className="form-control"
                        id="cnpj"
                        placeholder="CNPJ"
                        defaultValue={cnpjMask(userData.localCnpj)}
                        register={register("localCnpj")}
                        error={errors?.localCnpj?.message}
                        onChange={handleLocalCnpjInput}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Nome Local de Compra
                      <span className="text-danger">*</span>
                    </label>
                    <div className="form-floating">
                      <Input
                        id="localName"
                        type="text"
                        className="form-control"
                        placeholder="Nome Local de Compra"
                        register={register("localName")}
                        defaultValue={userData.localName}
                        error={errors?.localName?.message}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Nome Farmacêutico
                      <span className="text-danger">*</span>
                    </label>
                    <div className="form-floating">
                      <Input
                        id="localName"
                        type="text"
                        className="form-control"
                        placeholder="Nome Farmacêutico"
                        register={register("pharmaceuticalName")}
                        defaultValue={userData.name}
                        error={errors?.pharmaceuticalName?.message}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="row">
                    <div className="col-8">
                      <div className="form-group">
                        <label className="form-label">
                          Nº CRF Farmacêutico
                          <span className="text-danger">*</span>
                        </label>
                        <div className="form-floating">
                          <Input
                            id="localName"
                            type="text"
                            className="form-control"
                            placeholder="Nº CRF Farmacêutico"
                            defaultValue={crfMask(userData.crf)}
                            register={register("pharmaceuticalCrf")}
                            onChange={handleCrfInput}
                            error={errors?.pharmaceuticalCrf?.message}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label className="form-label">
                          CRF UF
                          <span className="text-danger">*</span>
                        </label>
                        <div className="form-floating">
                          <SelectInput
                            name="pharmaceuticalCrfUf"
                            control={control}
                            placeholder="Selecione"
                            changeOptionSelected={handleSelectBrazilianState}
                            error={errors?.pharmaceuticalCrfUf?.message}
                            defaultValue={userData?.crfUf}
                            options={brazilianStates?.map((o) => new SelectOptionItem(o.sigla, o.sigla))}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label className="form-label">
                      E-mail Comercial
                      <span className="text-danger">*</span>
                    </label>
                    <div className="form-floating">
                      <Input
                        id="email"
                        type="text"
                        className="form-control"
                        placeholder="E-mail Comercial"
                        register={register("email")}
                        defaultValue={userData.email}
                        error={errors?.email?.message}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 pb-4">
                  <div className="form-group">
                    <label htmlFor="medicamento">Informe os Itens Comprados</label>
                    <div className="row g-0 mb-2">
                      <div className="col">
                        <input
                          id="medicamento"
                          type="text"
                          className="form-control"
                          placeholder="Nome do Medicamento"
                          value={item}
                          onChange={(e) => setItem(e.target.value)}
                          onKeyPress={(e) => removeEnterKeyEvent(e)}
                        />
                      </div>
                      <div className="col col-auto">
                        <button onClick={() => handleNewItem(item)} type="button" className="btn btn-primary ms-2">
                          <i className="bi bi-plus"></i>
                        </button>
                      </div>
                    </div>
                    <ul className="list-group small mx-3">
                      {itensList.map((item, i) => (
                        <ItemCard key={i} item={item} itensList={itensList} setItensList={setItensList} />
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <div className="form-floating">
                      <textarea className="form-control"
                        placeholder="Descrição Itens Comprados"
                        id="descricao"
                        style={{height: "100px"}}
                        {...register("purchaseDescription")}
                      ></textarea>
                      <label htmlFor="descricao">Descrição Itens Comprados</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-light" data-bs-dismiss="modal" onClick={closeModal} type="button">
                Cancelar
              </button>
              <Button type="submit" className="btn btn-success">
                Registrar
                {!!isLoading && (
                  <CircularProgress
                    color="inherit"
                    size={12}
                    className="align-text-top ms-2"
                  />
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export {RegisterBuy};

