import {ReactNode} from "react";
import ContainerAnimation from "../../../../components/Animations/ContainerAnimation";

interface MyDataContainerProps {
  children: ReactNode;
}

const MyDataContainer = ({children}: MyDataContainerProps) => {
  return (
    <ContainerAnimation className="content container flex-grow-1 d-flex flex-column justify-content-center" >
      <div className="row g-0 justify-content-center">
        <div className="bg-box-card text-center p-5 col-12">
          <h1 className="mb-3 mb-xl-4 text-primary">Receita Digital</h1>
          <h3>Meus Dados</h3>
          {children}
        </div>
      </div>
    </ContainerAnimation>
  );
};

export default MyDataContainer;
