import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import {NewPasswordData} from "../../types/auth";
import {api} from "../../services";
import {toast} from "../../utils/toast";
import Input from "../Input";
import {autoCloseValidationMessage} from "../../pages/PasswordRecovery/utils/pwdRecoveryPopUp";
import {Button} from "react-bootstrap";

interface NewPasswordFormProps {
  code: string;
  emailOrCpf: string;
}

const NewPasswordForm = ({code, emailOrCpf}: NewPasswordFormProps) => {
  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .required("*Campo obrigatório")
      .min(8, "*A senha deve ter pelo menos 8 caracteres")
      .matches(
        RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})(?=.*[!.@#$%^&*\"'¨()_+=`´{}~,<>;:?|\\]\\\\/[-])"
        ),
        "*Utilize letras maiúsculas e minúsculas, números e caracteres especiais (*#.!@)"
      ),
    newPasswordConfirm: yup
      .string()
      .required("*Campo obrigatório")
      .oneOf([yup.ref("newPassword")], "*As senhas não conferem"),
  });

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<NewPasswordData>({resolver: yupResolver(schema)});

  const onSubmit = async (data: NewPasswordData) => {
    Swal.showLoading();
    const body = {code, emailOrCpf, newPassword: data.newPassword};
    try {
      await api.post("/Auth/passwordReset", body);
      Swal.hideLoading();
      Swal.close();
      toast.fire({
        icon: "success",
        title: "Senha alterada com sucesso!",
      });
    } catch (error: any) {
      Swal.hideLoading();

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro. Por favor, tente mais tarde.";
      }

      autoCloseValidationMessage(message, 5000);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="px-2">
        <div className="form-box p-3">
          <div className="row">
            <div className="form-group text-start col-12 mb-3 p-0">
              <label className="form-label">
                Nova senha
                <span className="text-danger">*</span>
              </label>

              <Input
                register={register("newPassword")}
                error={errors?.newPassword?.message}
                placeholder="Digite a nova senha"
                autoFocus
                className="mb-3"
                isPasswordInput
              />
            </div>

            <div className="form-group text-start col-12 mb-3 p-0">
              <label className="form-label">
                Repetir nova senha
                <span className="text-danger">*</span>
              </label>

              <Input
                register={register("newPasswordConfirm")}
                error={errors?.newPasswordConfirm?.message}
                placeholder="Digite novamente a nova senha"
                isPasswordInput
              />
            </div>

            <div className="col-12 text-center mb-3">
              <div className="form-text">(*) campos obrigatórios.</div>
            </div>

            <Button type="submit" className="col-12 btn btn-primary mt-3">
              Salvar senha
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default NewPasswordForm;
