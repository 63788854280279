import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Input from "../../components/Input";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {PaginationSearchProps} from "../../types";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {Pagination} from "@mui/material";
import {api} from "../../services";
import ValidatedRecipeAccordion from "./components/ValidatedRecipeAccordion";
import {RecipeQueryResult} from "../../types/recipe";
import {InfoCardSkeleton} from "../../components/Skeletons/infoCardSkeleton";
import {ErrorCard} from "../../components/Cards/ErrorCard";

const ValidatedRecipes = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean | null>(null);
    const [infos, setInfos] = useState<RecipeQueryResult[] | null>(null);
    const [totalPages, setTotalPages] = useState<number>();

    const navigate = useNavigate();

    const schema = yup.object().shape({
        search: yup.string()
    });

    const {
        register,
        handleSubmit,
        getValues,
        formState: {errors},
    } = useForm<PaginationSearchProps>({
        resolver: yupResolver(schema),
    });

    async function getData() {
        setLoading(true);
        setTotalPages(0);

        try {
            const {data} = (await api.get(`/receita/search`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
                params: {
                    size: 10,
                    page: currentPage,
                    search: getValues("search")
                }
            }));

            if (!data.data) {
                setTimeout(() => {
                    setLoading(false);
                }, 1500);

                return setInfos(null);
            }

            setInfos(data.data);
            setTotalPages(data.totalPages);

            setTimeout(() => {
                setLoading(false);
            }, 1500);
        } catch (error) {
            setInfos(null);

            setLoading(false);
        }
    }

    const handleChangePage = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setCurrentPage(value);
        getData();
    };


    const onSubmit = () => {
        getData();
    };

    useEffect(() => {
        navigate('.', {state: {backgroundNotImage: true}});
        getData();
    }, [navigate]);

    return (
        <>
            <div className="content container flex-grow-1 d-flex flex-column">
                <div className="row g-2 align-items-center justify-content-between mb-4">
                    <div className="col-12 col-md-6 col-lg-5">
                        <h2 className="mb-0">Receitas Validadas</h2>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row g-0">
                                <div className="col pe-2">
                                    <div className="form-group mb-0 position-relative has-icon-left">
                                        <Input
                                            placeholder="Buscar por código, data ou nome"
                                            register={register("search")}
                                            error={errors?.search?.message}
                                        />
                                        <div className="form-control-icon">
                                            <i className="bi bi-search"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-auto">
                                    <button type="submit" className="btn btn-primary">
                                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-12 align-items-center justify-content-center mt-4">
                        {
                            infos && infos.length > 0 ?
                                infos?.map((elm, i) => (loading ? <>{i < 2 && <InfoCardSkeleton key={i} />}</> : <ValidatedRecipeAccordion key={i} {...elm} />))
                                : (loading != null && loading === false &&
                                    <ErrorCard title="Nenhuma receita encontra com base nos valores informados." text={getValues("search")} />
                                )
                        }
                    </div>

                    <div className="col-12 align-items-center justify-content-center">
                        <Pagination
                            color="primary"
                            size="large"
                            count={totalPages}
                            page={currentPage}
                            onChange={handleChangePage}
                            className="mt-4 d-flex justify-content-center"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ValidatedRecipes;