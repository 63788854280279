import {Routes, Route} from "react-router-dom";
import ValidationPage from "../pages/ValidationPage";
import Login from "../pages/Login";
import LandingPage from "../pages/LandingPage";
import SimplePage from "../components/SimplePage";
import PasswordRecovery from "../pages/PasswordRecovery";
import {PrivateRoute, PublicRoute} from "./route";
import PharmaceuticalRegister from "../pages/Register/PharmaceuticalRegister";
import LoggedPage from "../components/LoggedPage";
import PharmaceuticalMyData from "../pages/MyData/PharmaceuticalMyData";
import NotFound from "../pages/NotFound";
import ValidatedRecipes from "../pages/ValidatedRecipes";
import Logout from "../pages/Logout";

const Router = () => (
  <Routes>
    <Route element={<SimplePage />}>
      <Route element={<PublicRoute />}>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/recovery" element={<PasswordRecovery />} />
        <Route path="/register" element={<PharmaceuticalRegister />} />
      </Route>
    </Route>

    <Route element={<LoggedPage />}>
      <Route element={<PrivateRoute />}>
        <Route path="/home" element={<LandingPage />} />
        <Route path="/validation/:code" element={<ValidationPage />} />
        <Route path="/meus-dados" element={<PharmaceuticalMyData />} />
        <Route path="/validated-recipes" element={<ValidatedRecipes />} />
      </Route>
    </Route>

    <Route element={<SimplePage />}>
      <Route path="*" element={<NotFound />} />
    </Route>

    <Route path="/logout" element={<Logout />} />
  </Routes>
);

export default Router;
