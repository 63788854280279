export const pdfBase64ToLink = (pdfBase64: string) => {
  return "data:application/pdf;base64," + pdfBase64;
}

export const base64ToUint8Array = (base64: string) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};
