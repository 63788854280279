const Footer = () => (
  <footer className="site-footer">
    <div className="container">
      <div className="row">
        <div className="col-12 text-muted text-center">
          <p className="mb-0 mt-2">
            {`${new Date().getFullYear()} © Conectadoc.`}
          </p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
