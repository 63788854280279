import {RecipePdfQueryResult, RecipeQueryResult} from '../../../../types/recipe';
import moment from 'moment';
import {InfoCard} from '../../../../components/Cards/InfoCard';
import {useState} from 'react';
import AccordionContentAnimation from '../../../../components/Animations/AccordionContentAnimation';
import {PDF} from '../../../../components/Modals/PDFModal';
import {api} from '../../../../services';
import {Tooltip} from '@mui/material';

function ValidatedRecipeAccordion({
    burnDate,
    recipeCode,
    valid,
    patient,
    local,
    dispensations}: RecipeQueryResult) {

    const [expanded, setExpanded] = useState<boolean>(false)
    const [base64PDFFile, setBase64PDFFile] = useState<string | null>(null)
    const [pdfModal, setPdfModal] = useState<boolean>(false);

    const closeModal = () => {setPdfModal(false);};
    const openModal = async () => {
        if (base64PDFFile != null) {
            setPdfModal(true);
            return;
        }

        try {
            const {data} = (await api.get(`/receita/${recipeCode}`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            })) as {
                data: RecipePdfQueryResult;
            };

            if (!data) {
                return setBase64PDFFile(null);
            }

            setBase64PDFFile(data.base64PDFFile);
            setPdfModal(true);
        } catch (error) {
            setBase64PDFFile(null);
        }

    };

    return (
        <>
            <div className={'accordion-card border ' + (expanded ? 'mb-0' : 'mb-3')}>
                <div className='w-full p-4'>
                    <div className='row align-items-center gy-2'>
                        <div className='col col-auto'>
                            <i className='bi bi-shield-check h3'></i>
                        </div>
                        <div className='col'>
                            <div className='row gy-2'>
                                <div className='col-12 col-md-6 col-xl-4 col-xxl-2'>
                                    <p className='text-muted mb-0 small'>Código</p>
                                    <Tooltip title={recipeCode} placement="top">
                                        <h6 className='text-truncate'>{recipeCode}</h6>
                                    </Tooltip>
                                </div>
                                <div className='col-12 col-md-6 col-xl-4 col-xxl-2'>
                                    <p className='text-muted mb-0 small'>Paciente</p>
                                    <h6 className='text-truncate'>{patient.name}</h6>
                                </div>
                                <div className='col-12 col-md-6 col-xl-4 col-xxl-2'>
                                    <p className='text-muted mb-0 small'>Local</p>
                                    <h6 className='text-truncate'>{local.name}</h6>
                                </div>
                                <div className='col-12 col-md-6 col-xl-4 col-xxl-2'>
                                    <p className='text-muted mb-0 small'>Data da Queima</p>
                                    <h6 className='text-truncate'>{moment(burnDate).format("DD/MM/YYYY HH:mm")}</h6>
                                </div>
                                <div className='col-12 col-md-6 col-xl-4 col-xxl-2'>
                                    <p className='text-muted mb-0 small'>Tipo de Documento</p>
                                    <h6 className='text-truncate'>Receita de Controle Especial</h6>
                                </div>
                                <div className='col-12 col-md-6 col-xl-4 col-xxl-2 align-self-center text-center text-md-start text-xxl-end'>
                                    <button onClick={openModal} className='btn btn-sm btn-outline-dark' data-bs-toggle='modal' data-bs-target='#modalReceita'><i className='bi bi-file-pdf me-2'></i>Ver Receita</button>
                                </div>
                            </div>
                        </div>
                        <div className='col col-auto' onClick={() => setExpanded(!expanded)}>
                            <i className={'h5 bi ' + (expanded ? 'bi-chevron-up' : 'bi-chevron-down')}></i>
                        </div>
                    </div>
                </div>
            </div >
            {expanded && valid && dispensations && (
                <AccordionContentAnimation
                    className="card border border-top-0 bg-light mx-3 mx-md-5"
                    expanded={expanded}
                >
                    {dispensations.length > 0 ? (
                        <>
                            <div className="card-body p-4">
                                <h3 className="mb-5">Informações</h3>
                                {dispensations.map((elm, i) => <InfoCard key={i} {...elm} />)}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="card-body p-4">
                                <h3 className="mb-5">Informações</h3>
                                <div className="row mt-4 pb-4 justify-content-between border-bottom">
                                    <h3 className="text-center" style={{color: "#4e5f8c"}}>
                                        Receita sem registro de uso
                                    </h3>
                                </div>
                            </div>
                        </>
                    )}
                </AccordionContentAnimation>
            )}
            {base64PDFFile && pdfModal && (
                <div className="modal-content">
                    <PDF pdf={base64PDFFile} code={recipeCode ? recipeCode : ""} isOpen={pdfModal} closeModal={closeModal} />
                </div>
            )}
        </>
    );
}

export default ValidatedRecipeAccordion;