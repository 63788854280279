import {ChangeEvent, ReactNode} from "react";
import {Form} from "react-bootstrap";
import {SelectOptionItem} from "../../types";

import {FormControl} from "@mui/material";
import {Controller} from "react-hook-form";

interface SelectInputProps {
  control: any;
  name: string;
  placeholder?: string;
  error?: string;
  defaultValue?: number | string;
  options: SelectOptionItem[];
  children?: ReactNode;
  customOnChange?: () => void;
  changeOptionSelected?: (event: ChangeEvent<any>) => void;
  isDisabled?: boolean;
}

const SelectInput = (props: SelectInputProps) => {
  const getValue = (value: any): any => {
    return value != "" ? value : (props.defaultValue ?? "");
  };

  return (
    <>
      <FormControl fullWidth>
        <Controller
          name={props.name}
          control={props.control}
          defaultValue={props.defaultValue ?? ""}
          render={({field: {onChange, value}}) => (
            <Form.Select
              aria-label={props.placeholder}
              onChange={(event) => {
                onChange();
                if (props.changeOptionSelected) {
                  props.changeOptionSelected(event);
                }
                props.customOnChange?.();
              }}
              value={getValue(value)}
              disabled={props.isDisabled}>
              <option value="" disabled>{props.placeholder}</option>
              {props.options.map((option) => (
                <option key={option.key} value={option.key}>
                  {option.value}
                </option>
              ))}
            </Form.Select>
          )}
        />
      </FormControl>

      {!!props.error && (
        <div className="text-start text-danger">
          <small>{props.error}</small>
        </div>
      )}
    </>
  );
};

export default SelectInput;
