import {Outlet} from "react-router-dom";

import {SimpleHeader} from "../SimpleHeader";
import Footer from "../Footer";
import {StyledBackground} from "./style";

const Page = () => {
  return (
    <div className="min-vh-100 d-flex flex-column">
      <StyledBackground className="bg-receita">
        <div id="simple-main" className="layout-horizontal">
          <SimpleHeader />
          <Outlet />
          <Footer />
        </div>
      </StyledBackground>
    </div>
  );
};

export default Page;
