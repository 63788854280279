import {Outlet, useLocation} from "react-router-dom";
import Footer from "../Footer";
import {Header} from "../Header";

interface LocationState {
  backgroundNotImage?: boolean;
}

const LoggedPage = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const hasBackgroundNotImage = state?.backgroundNotImage;
  
  return (
    <div className={(hasBackgroundNotImage ? 'br-none' : 'bg-receita') + " min-vh-100 d-flex flex-column"}>
        <div id="simple-main" className="layout-horizontal">
          <Header />
          <Outlet />
          <Footer />
        </div>
    </div>
  );
}

export default LoggedPage;
