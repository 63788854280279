import moment from "moment";
import "moment/locale/pt-br";
import {Dispensation} from "../../../types/recipe";

export const InfoCard = ({
  type,
  purchaseDate,
  purchaseDescription,
  localName,
  localCnpj,
  pharmaceutical,
  medicines}: Dispensation) => {
  return (
    <div className="row mt-4 pb-4 justify-content-between border-bottom">
      <div className="col-12 col-md-4">
        <p className="text-muted mb-0 small">Local de Compra</p>
        <h6>{localName}</h6>
      </div>
      <div className="col-12 col-md-4">
        <p className="text-muted mb-0 small">CNPJ</p>
        <h6>{localCnpj}</h6>
      </div>
      <div className="col-12 col-md-4">
        <p className="text-muted mb-0 small">Data da Compra</p>
        <h6>{moment(new Date(purchaseDate)).locale("pt-br").format("L")} - {moment(new Date(purchaseDate)).locale("pt-br").format("LT")}</h6>
      </div>
      {/* <div className="col-12 col-md-6 col-xxl-4">
        <p className="text-muted mb-0 small">Vendedor</p>
        <h6>{vendedor.nome}</h6>
      </div> */}
      <div className="col-12 col-md-4">
        <p className="text-muted mb-0 small">Farmacêutico</p>
        <h6>{pharmaceutical.name}</h6>
      </div>
      <div className="col-12 col-md-4">
        <p className="text-muted mb-0 small">CRF nº</p>
        <h6>{pharmaceutical.crf + "-" + pharmaceutical.crfUf}</h6>
      </div>
      <div className="col-12 col-md-4">
        <p className="text-muted mb-0 small">Dispensação</p>
        <h6>{type === 1 ? 'TOTAL' : 'PARCIAL'}</h6>
      </div>
      {purchaseDescription.length > 0 && (
        <div className="col-12 col-md-4">
          <p className="text-muted mb-0 small">Descrição da Compra</p>
          <h6>{purchaseDescription}</h6>
        </div>
      )}
      <div className="col-12 col-md-8">
        <p className="text-muted mb-0 small">Itens Comprados</p>
        {medicines.map((elm, ind) => (
          <h6>{(ind + 1) + "° - " + elm.description}</h6>
        ))}
      </div>
    </div>
  );
};
