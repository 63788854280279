import {Box} from "@mui/material";
import {ValidationCardSkeleton} from "../../../../components/Skeletons/ValidationCardSkeleton";
import {InfoCardSkeleton} from "../../../../components/Skeletons/infoCardSkeleton";

const ValidationPageLoading = () => {
    return (
        <>
            <Box className="card mb-0 sticky-xl-top">
                <ValidationCardSkeleton />
            </Box>
            <Box className="card-footer text-center pb-0">
                <Box width={150} height={36} className="btn mb-4 mx-1 btn-secondary"></Box>
                <Box width={150} height={36} className="btn mb-4 mx-1 btn-secondary"></Box>
            </Box>
            <Box
                className="card border border-top-0 bg-light mx-3 mx-md-5"
                style={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: "0",
                }}
            >
                <Box className="card-body p-4">
                    <InfoCardSkeleton />
                    <InfoCardSkeleton />
                </Box>
            </Box>
        </>
    );
}

export default ValidationPageLoading;