import {Navigate, Outlet} from "react-router-dom";
import {useAuth} from "../providers/Auth";

export const PrivateRoute = () => {
    const {token} = useAuth();
    return token ? <Outlet /> : <Navigate to="/" />;
};

export const PublicRoute = () => {
    const {token} = useAuth();
    return token ? <Navigate to="/home" /> : <Outlet />;
};
