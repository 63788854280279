/* eslint-disable jsx-a11y/anchor-is-valid */
import Logo from "../../assets/images/logo/logo.svg";
import {useNavigate} from "react-router-dom";

export const SimpleHeader = () => {
  const navigate = useNavigate();

  return (
    <header className="mb-5">
      <div className="header-top bg-box">
        <div className="row align-items-center justify-content-center">
          <div className="col col-auto">
            <div className="logo">
              <a style={{cursor: "pointer"}} onClick={() => navigate("")}>
                <img
                  src={Logo}
                  alt="Logo"
                  width="212"
                  className="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </header >
  );
};
