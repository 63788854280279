import {ErrorCardProps} from "../../../types";
import "bootstrap-icons/font/bootstrap-icons.css";
import "moment/locale/pt-br";

const ErrorCard = ({text, title}: ErrorCardProps) => {
  return (
    <div className="card-header bg-danger">
      <div className="row align-items-center justify-content-center">
        <div className="col col-auto">
          <i className="bi bi-x-circle text-white display-4"></i>
        </div>
        <div className="col-12 col-md-auto text-center text-md-start pt-3 pt-md-0">
          <p className="text-white mb-0">{title}</p>
          <h5 className="mb-0 text-white">
            <b>{text}</b>
          </h5>
        </div>
      </div>
    </div>
  );
};

export {ErrorCard};
