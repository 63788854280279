
export const validateCpf = (inputValue: string | undefined) => {
  if (!inputValue) {
    return false;
  }

  inputValue = inputValue.replace(/\D/g, "");

  const cpfNumbersList = inputValue
    .split("")
    .map((cpfStrChar) => parseInt(cpfStrChar));

  if (cpfNumbersList.length !== 11) {
    return false;
  }

  // Check CPF with all digits the same
  if (cpfNumbersList.every((digit) => digit === cpfNumbersList[0])) {
    return false;
  }

  let digit1 = 0;
  // digit2 = 0

  // First Check Digit
  let sum = 0;
  let sumNumber = 2;

  for (const digit of cpfNumbersList.slice(0, 9).reverse()) {
    sum += digit * sumNumber;
    sumNumber++;
  }

  let rest = sum % 11;

  if (rest < 2) {
    if (cpfNumbersList[9] !== 0) {
      return false;
    } else {
      digit1 = 0;
    }
  } else {
    if (cpfNumbersList[9] !== 11 - rest) {
      return false;
    } else {
      digit1 = 11 - rest;
    }
  }

  // Second Check Digit
  sum = digit1 * 2;
  sumNumber = 3;

  for (const digit of cpfNumbersList.slice(0, 9).reverse()) {
    sum += digit * sumNumber;
    sumNumber++;
  }

  rest = sum % 11;

  if (rest < 2) {
    if (cpfNumbersList[10] !== 0) {
      return false;
    } // else digit2 = 0
  } else {
    if (cpfNumbersList[10] !== 11 - rest) {
      return false;
    } // else digit2 = 11 - rest
  }

  return true;
};