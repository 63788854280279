/* eslint-disable jsx-a11y/anchor-is-valid */
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {CardProps} from "../../types";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {CircularProgress} from "@mui/material";
import Button from "../../components/Button";
import ContainerAnimation from "../../components/Animations/ContainerAnimation";

const LandingPage = () => {
  const navigate = useNavigate();
  const isLoading = false;

  const [infoModal, setInfoModal] = useState(false);

  const schema = yup.object().shape({
    code: yup
      .string()
      .required("Insira a chave de validação")
      .min(8, "Formato inválido, verifique o código informado!"),
  });

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<CardProps>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (props: CardProps) => {
    const {code} = props;
    navigate(`/validation/${code}`);
  };

  return (
    <ContainerAnimation className="content container flex-grow-1 d-flex flex-column justify-content-center">
      <div className="row g-0 justify-content-center">
        <div className="bg-box-card text-center p-5 col-12 col-md-10 col-lg-8 col-xl-6 col-xxl-5 modal-md">
          <h1>Receita Digital</h1>
          <p className="mb-5">
            Informe a chave de validação da receita.
          </p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group position-relative has-icon-left">
              <input
                type="text"
                className="form-control form-control-lg text-center"
                placeholder="Chave de validação da receita"
                {...register("code")}
              />
              <div className={"form-control-icon"}>
                <i className="bi bi-lock"></i>
              </div>
            </div>
            {!!errors && (
              <p className="text-danger mb-0 alert-link">
                {errors.code?.message}
              </p>
            )}
            <Button type="submit" className="btn btn-primary btn-block btn-lg mt-4" disabled={isLoading}>
              Validar Chave
              {!!isLoading && (
                <CircularProgress
                  color="inherit"
                  size={12}
                  className="align-text-top ms-2"
                />
              )}
            </Button>

            <Button
              onClick={() => navigate("/validated-recipes")}
              className="btn btn-outline-primary btn-block btn-lg mt-3"
              disabled={isLoading}
            >
              Receitas Validadas
            </Button>
          </form>

          <div className="text-center mt-5 text-lg">
            <p className="text-gray-600">
              Não sabe o que é uma chave de validação?{" "}
              {/* // TODO adicionar link */}
              <a
                onMouseEnter={() => setInfoModal(true)}
                onMouseLeave={() => setInfoModal(false)}
                className="font-bold cursor-pointer"
              >
                Descubra aqui
              </a>
              .
            </p>

          </div>
        </div>
      </div>
    </ContainerAnimation>
  );
};

export default LandingPage;
