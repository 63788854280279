import {motion} from "framer-motion";
import {ReactNode} from "react";

interface AccordionContentAnimationProps {
  children: ReactNode;
  expanded: boolean;
  className?: string;
}

const AccordionContentAnimation = ({
  children,
  expanded,
  className,
}: AccordionContentAnimationProps) => {
  return (
    <motion.div
      className={className}
      style={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: "0",
        overflow: 'hidden'
      }}

      initial={{height: 0, overflow: 'hidden'}}
      animate={{height: expanded ? 'auto' : 0}}
      transition={{duration: 0.3}}
      exit={{opacity: 0}}
    >
      {children}
    </motion.div>
  );
};

export default AccordionContentAnimation;
