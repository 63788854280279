import ContainerAnimation from "../../components/Animations/ContainerAnimation";
import PasswordRecoveryForm from "./components/PasswordRecoberyForm";

const PasswordRecovery = () => {
    return (
        <ContainerAnimation className="content container flex-grow-1 d-flex flex-column justify-content-center">
            <div className="row g-0 justify-content-center">
                <div className="bg-box-card text-center p-5 col-12 col-md-10 col-lg-8 col-xl-6 col-xxl-5 modal-md">
                    <h1 className="mb-3 mb-xl-4 text-primary">Receita Digital</h1>
                    <h3>Recuperar senha</h3>
                    <p className="mb-3 mb-xl-4">Preencha os campos abaixo para receber um email de recuperação de senha.</p>
                    <PasswordRecoveryForm />
                </div>
            </div>
        </ContainerAnimation>
    );
};

export default PasswordRecovery;
