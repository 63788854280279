import { InputHTMLAttributes, useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

import * as C from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  register?: UseFormRegisterReturn;
  label?: string;
  error?: string;
  isPasswordInput?: boolean;
  containerClassName?: string;
  type?: string;
  isPriceField?: boolean;
  isFeeField?: boolean;
  labelClassName?: string;
}

const Input = ({
  register,
  label,
  error,
  isPasswordInput,
  containerClassName,
  isPriceField,
  isFeeField,
  type = "text",
  labelClassName,
  ...rest
}: InputProps) => {
  const [isShowingPassword, setIsShowingPassword] = useState(() =>
    isPasswordInput ? false : true
  );

  const togglePasswordVisiblity = () => {
    setIsShowingPassword(!isShowingPassword);
  };

  return (
    <div className={containerClassName}>
      {label && (
        <label htmlFor="name" className={"mb-2 " + labelClassName}>
          {label}
        </label>
      )}

      <div
        className={
          isPriceField || isFeeField
            ? "row align-items-center no-gutters flex-nowrap"
            : ""
        }
      >
        {!!isPriceField && <div className="col col-auto pr-2">R$</div>}

        <C.InputContainer
          isPasswordInput={isPasswordInput}
          className={`position-relative ${
            isPriceField || isFeeField ? "col" : ""
          }`}
          hasError={!!error}
        >
          <input
            {...register}
            {...rest}
            type={isShowingPassword ? type : "password"}
            className={`
              form-control ${rest.className ?? ""} 
              ${type === "search" ? "border-0" : ""}
            `}
          />

          {isPasswordInput && (
            <C.PasswordIcon
              className="fs-4 position-absolute top-50 end-0 translate-middle-y pointer-event"
              onClick={togglePasswordVisiblity}
            >
              {isShowingPassword ? (
                <i className="uil uil-eye-slash pe-3" />
              ) : (
                <i className="uil uil-eye pe-3" />
              )}
            </C.PasswordIcon>
          )}
        </C.InputContainer>

        {!!isFeeField && <div className="col col-auto pl-2">%</div>}
      </div>

      {!!error && (
        <div className="text-start text-danger">
          <small>{error}</small>
        </div>
      )}
    </div>
  );
};

export default Input;
