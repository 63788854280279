import {yupResolver} from "@hookform/resolvers/yup";
import {CircularProgress} from "@mui/material";
import React from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import {validateCpf} from "../../../../utils/formValidations";
import {cpfMask} from "../../../../utils/formMasks";
import {useAuth} from "../../../../providers/Auth";
import {UserLoginData} from "../../../../types/auth";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import LinkButton from "../../../../components/LinkButton";


const LoginForm = () => {
  const navigate = useNavigate();
  const {isLoading, login} = useAuth();

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("*Campo obrigatório")
      .when("isEmail", {
        is: true,
        then: yup.string().email("*Formato incorreto"),
        otherwise: yup
          .string()
          .test("cpfValidator", "*CPF inválido", validateCpf)
          .transform((value) => value.replace(/\D/g, "")),
      }),
    password: yup.string().required("*Campo obrigatório"),
    isEmail: yup.boolean(),
    keepConnected: yup.boolean(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<UserLoginData>({resolver: yupResolver(schema)});

  const onSubmit = async (data: UserLoginData) => {
    login(data, navigate);
  };

  const handleUserInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue.match("@")) {
      setValue("isEmail", true);
      setValue("email", inputValue);
    }
    if (!inputValue.match(/([a-z])/gi)) {
      setValue("isEmail", false);
      setValue("email", cpfMask(inputValue));
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="aos-init"
      data-aos="fade-up"
      data-aos-delay="200"
      data-aos-once
    >
      <div className="form-box p-3">
        <div className="row">
          <div className="form-group text-start col-12 mb-3 p-0">
            <label className="form-label">
              Login (E-mail ou CPF)
              <span className="text-danger">*</span>
            </label>

            <Input
              placeholder="Digite seu e-mail ou CPF"
              register={register("email")}
              error={errors?.email?.message}
              onChange={handleUserInput}
              type="text"
              autoFocus
            />
          </div>

          <div className="form-group text-start col-12 mb-3 p-0">
            <label className="form-label">
              Senha
              <span className="text-danger">*</span>
            </label>

            <Input
              placeholder="Digite sua senha"
              isPasswordInput
              register={register("password")}
              error={errors?.password?.message}
            />
          </div>

          <div className="col-12 text-center mb-3">
            <div className="form-text">(*) campos obrigatórios.</div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <div className="row pb-3">
          <div className="col-12 mb-3">
            <Button type="submit" className="btn btn-primary btn-lg w-100" disabled={isLoading}>
              Entrar
              {!!isLoading && (
                <CircularProgress
                  color="inherit"
                  size={12}
                  className="align-text-top ms-2"
                />
              )}
            </Button>
          </div>

          <div className="col-12 text-center mb-0 d-flex flex-column gap-3 flex-sm-row justify-content-between">
            <LinkButton
              className="fs-6"
              onClick={() => navigate("/register")}
              disabled={isLoading}
            >
              Cadastre-se
            </LinkButton>

            <LinkButton
              className="fs-6"
              onClick={() => navigate("/recovery")}
              disabled={isLoading}
            >
              Esqueceu sua senha?
            </LinkButton>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
