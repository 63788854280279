import axios from "axios";
import {BrazilianState} from "../types/ibge";

export const getBrazilianStates = async (
  setBrazilianStates: React.Dispatch<React.SetStateAction<BrazilianState[]>>
) => {
  const response = await axios.get(
    "https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome"
  );

  setBrazilianStates(response.data);
};
