export const cpfMask = (inputValue: string) => {
  inputValue = inputValue.replace(/\D/g, "");

  const cpfSubstringsList = inputValue.split("");
  const formattedCpf = cpfSubstringsList.slice(0, 11);
  const size = formattedCpf.length;

  if (size > 9) {
    return formattedCpf
      .join("")
      .replace(/^(\d{3})(\d{3})(\d{3})(\d{1,})$/g, "$1.$2.$3-$4");
  } else if (size > 6) {
    return formattedCpf
      .join("")
      .replace(/^(\d{3})(\d{3})(\d{1,})$/g, "$1.$2.$3");
  } else if (size > 3) {
    return formattedCpf.join("").replace(/^(\d{3})(\d{1,})$/g, "$1.$2");
  } else {
    return formattedCpf.join("");
  }
};

export const cnpjMask = (inputValue: string) => {
  inputValue = inputValue.replace(/\D/g, "");

  const inputValueSubstrings = inputValue.split("");
  const formattedCnpj = inputValueSubstrings.slice(0, 14);
  const size = formattedCnpj.length;

  if (size > 12) {
    return formattedCnpj
      .join("")
      .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,})$/g, "$1.$2.$3/$4-$5");
  } else if (size > 8) {
    return formattedCnpj
      .join("")
      .replace(/^(\d{2})(\d{3})(\d{3})(\d{1,})$/g, "$1.$2.$3/$4");
  } else if (size > 5) {
    return formattedCnpj
      .join("")
      .replace(/^(\d{2})(\d{3})(\d{1,})$/g, "$1.$2.$3");
  } else if (size > 2) {
    return formattedCnpj.join("").replace(/^(\d{2})(\d{1,})$/g, "$1.$2");
  } else {
    return formattedCnpj.join("");
  }
};

export const crfMask = (inputValue: string) => {
  return "CRF-" + inputValue?.replace("CRF-","").replace("CRF","");
}

export const phoneMask = (inputValue: string) => {
  inputValue = inputValue.replace(/\D/g, "");

  const inputValueSubstrings = inputValue.split("");
  const formattedPhone = inputValueSubstrings.slice(0, 11);
  const size = formattedPhone.length;

  if (size === 11) {
    return formattedPhone
      .join("")
      .replace(/^(\d{2})(\d{1})(\d{4})(\d{1,})$/g, "($1) $2 $3-$4");
  } else if (size > 7) {
    return formattedPhone
      .join("")
      .replace(/^(\d{2})(\d{4})(\d{1,})$/g, "($1) $2-$3");
  } else if (size > 2) {
    return formattedPhone.join("").replace(/^(\d{2})(\d{1,})$/g, "($1) $2");
  } else {
    return formattedPhone.join("");
  }
};
