import {yupResolver} from "@hookform/resolvers/yup";
import {CircularProgress, SelectChangeEvent} from "@mui/material";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import {validateCpf} from "../../../../utils/formValidations";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import LinkButton from "../../../../components/LinkButton";
import {PharmaceuticalRegisterData} from "../../../../types/register";
import {useRegister} from "../../../../providers/Register";
import {cnpjMask, cpfMask, phoneMask} from "../../../../utils/formMasks";
import SelectInput from "../../../../components/SelectInput";
import {useEffect, useState} from "react";
import {BrazilianState} from "../../../../types/ibge";
import {getBrazilianStates} from "../../../../utils/brazilianStates";
import {SelectOptionItem} from "../../../../types";


const PharmaceuticalRegisterForm = () => {
  const navigate = useNavigate();
  const {isLoading, registerPharmaceutical} = useRegister();
  const [brazilianStates, setBrazilianStates] = useState<BrazilianState[]>([]);

  const schema = yup.object().shape({
    name: yup.string().required("*Campo obrigatório"),
    cpf: yup
      .string()
      .required("*Campo obrigatório")
      .test("cpfValidator", "*CPF inválido", validateCpf)
      .transform((value) => value.replace(/\D/g, "")),
    crf: yup.string().required("*Campo obrigatório"),
    crfUf: yup.string().required("*Campo obrigatório"),
    localName: yup.string().required("*Campo obrigatório"),
    localCnpj: yup.string().required("*Campo obrigatório"),
    phone: yup.string().required("*Campo obrigatório"),
    email: yup
      .string()
      .required("*Campo obrigatório")
      .email("*Formato incorreto"),
    password: yup.string().required("*Campo obrigatório")
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: {errors},
  } = useForm<PharmaceuticalRegisterData>({resolver: yupResolver(schema)});

  const onSubmit = async (data: PharmaceuticalRegisterData) => {
    registerPharmaceutical(data, navigate);
  };

  const handleCpfInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setValue("cpf", cpfMask(inputValue));
  };

  const handleLocalCnpjInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setValue("localCnpj", cnpjMask(inputValue));
  };

  const handlePhoneInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setValue("phone", phoneMask(inputValue));
  };

  const handleSelectBrazilianState = async (event: SelectChangeEvent<any>) => {
    const brazilianState = event.target.value;
    setValue("crfUf", brazilianState);
  };

  useEffect(() => {
    getBrazilianStates(setBrazilianStates);
  }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="aos-init"
      data-aos="fade-up"
      data-aos-delay="200"
      data-aos-once
    >
      <div className="form-box p-3 pb-0">
        <div className="row">
          <div className="form-group text-start col-12 col-md-6 mb-3 p-0">
            <div className="px-2">
              <label className="form-label">
                CRF
                <span className="text-danger">*</span>
              </label>

              <Input
                placeholder="Digite seu CRF"
                register={register("crf")}
                error={errors?.crf?.message}
                type="text"
                autoFocus
              />
            </div>
          </div>

          <div className="form-group text-start col-12 col-md-6 mb-3 p-0">
            <div className="px-2">
              <label className="form-label">
                UF
                <span className="text-danger">*</span>
              </label>

              <SelectInput
                name="crfUf"
                control={control}
                placeholder="Selecione"
                error={errors?.crfUf?.message}
                changeOptionSelected={handleSelectBrazilianState}
                options={brazilianStates?.map((o) => new SelectOptionItem(o.sigla, o.sigla))}>
              </SelectInput>
            </div>
          </div>

          <div className="form-group text-start col-12 col-md-6 mb-3 p-0">
            <div className="px-2">
              <label className="form-label">
                CPF
                <span className="text-danger">*</span>
              </label>

              <Input
                placeholder="Digite seu CPF"
                register={register("cpf")}
                onChange={handleCpfInput}
                error={errors?.cpf?.message}
                type="text"
                autoFocus
              />
            </div>
          </div>

          <div className="form-group text-start col-12 col-md-6 mb-3 p-0">
            <div className="px-2">
              <label className="form-label">
                Telefone
                <span className="text-danger">*</span>
              </label>

              <Input
                placeholder="Digite seu Telefone"
                register={register("phone")}
                onChange={handlePhoneInput}
                error={errors?.phone?.message}
                type="text"
                autoFocus
              />
            </div>
          </div>

          <div className="form-group text-start col-12 col-md-6 mb-3 p-0">
            <div className="px-2">
              <label className="form-label">
                Nome do Local
                <span className="text-danger">*</span>
              </label>

              <Input
                placeholder="Digite o nome do Local"
                register={register("localName")}
                error={errors?.localName?.message}
                type="text"
                autoFocus
              />
            </div>
          </div>

          <div className="form-group text-start col-12 col-md-6 mb-3 p-0">
            <div className="px-2">
              <label className="form-label">
                CNPJ do Local
                <span className="text-danger">*</span>
              </label>

              <Input
                placeholder="Digite CNPJ do Local"
                register={register("localCnpj")}
                onChange={handleLocalCnpjInput}
                error={errors?.localCnpj?.message}
                type="text"
                autoFocus
              />
            </div>
          </div>

          <div className="form-group text-start col-12 mb-3 p-0">
            <div className="px-2">
              <label className="form-label">
                Nome Completo
                <span className="text-danger">*</span>
              </label>

              <Input
                placeholder="Digite seu Nome Completo"
                register={register("name")}
                error={errors?.name?.message}
                type="text"
                autoFocus
              />
            </div>
          </div>

          <div className="form-group text-start col-12 col-md-6 mb-3 p-0">
            <div className="px-2">
              <label className="form-label">
                E-mail
                <span className="text-danger">*</span>
              </label>

              <Input
                placeholder="seu@email.com"
                register={register("email")}
                error={errors?.email?.message}
                type="text"
                autoFocus
              />
            </div>
          </div>

          <div className="form-group text-start col-12 col-md-6 mb-3 p-0">
            <div className="px-2">
              <label className="form-label">
                Senha
                <span className="text-danger">*</span>
              </label>

              <Input
                placeholder="Digite sua senha"
                isPasswordInput
                register={register("password")}
                error={errors?.password?.message}
              />
              <small id="emailHelp" className="form-text text-muted">
                Utilize letras maiúsculas e minúsculas, números e caracteres
                especiais (*#.!@)
              </small>
            </div>
          </div>


          <div className="col-12 text-center mb-3">
            <div className="form-text">(*) campos obrigatórios.</div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <div className="row pb-0">
          <div className="col-12 mb-3">
            <Button type="submit" className="btn btn-primary btn-lg w-100" disabled={isLoading}>
              Cadastrar
              {!!isLoading && (
                <CircularProgress
                  color="inherit"
                  size={12}
                  className="align-text-top ms-2"
                />
              )}
            </Button>
          </div>

          <div className="col-12 text-center mb-0 d-flex flex-column gap-3 flex-sm-row justify-content-center">
            <LinkButton
              className="fs-6"
              onClick={() => navigate("/login")}
              disabled={isLoading}
            >
              Fazer login
            </LinkButton>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PharmaceuticalRegisterForm;