import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";
import {User, UserEditData} from "../../types/user";
import {useAuth} from "../Auth";
import {api} from "../../services";
import {toast} from "../../utils/toast";

interface UserProviderProps {
  children: ReactNode;
}

interface UserProviderData {
  userData: User;
  editUserData: (data: UserEditData) => Promise<void>;
  isLoading: boolean;
}

export const UserContext = createContext<UserProviderData>(
  {} as UserProviderData
);

export const UserProvider = ({children}: UserProviderProps) => {
  const {token, userId} = useAuth();
  const [userData, setUserData] = useState<User>({} as User);
  const [isLoading, setIsLoading] = useState(false);

  const getUserData = async () => {
    const endpoint = `/User/perfil/${userId}`;

    try {
      const response = await api.get(endpoint);

      setUserData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const editUserData = async (data: UserEditData) => {
    setIsLoading(true);

    const body: UserEditData = {
      ...data
    };

    const endpoint = `/User/perfil/${userId}`;

    try {
      await api.put(endpoint, body);

      setIsLoading(false);

      toast.fire({
        icon: "success",
        title: "Dados atualizados com sucesso!",
      });

      getUserData();
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  useEffect(() => {
    if (token) {
      getUserData();
    }
  }, [token]);

  return (
    <UserContext.Provider
      value={{
        userData,
        editUserData,
        isLoading
      }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
