import {useEffect} from "react";
import {useAuth} from "../../providers/Auth";
import {useNavigate} from "react-router-dom";

const Logout = () => {
    const {logout, token} = useAuth();
    const navigate = useNavigate();
    
    useEffect(() => {
        if(token !== null && token !== "") {
            logout();
        }
        navigate('/login');
    }, [logout, navigate]);

    return (<></>);
}

export default Logout;