/* eslint-disable jsx-a11y/anchor-is-valid */
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import Logo from "../../assets/images/logo/logo.svg";
import {useNavigate} from "react-router-dom";
import {CardProps} from "../../types";
import DropdownMenu from "./components/DropdownMenu";
import Input from "../Input";

export const Header = () => {
  const navigate = useNavigate();

  const schema = yup.object().shape({
    code: yup
      .string()
      .required("")
      .min(8, "Formato inválido, verifique o código!"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<CardProps>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (props: CardProps) => {
    const {code} = props;

    reset();
    navigate(`/validation/${code}?d=${new Date().getTime()}`, {replace: true});
  };

  return (
    <header className="mb-5" style={{zIndex: 1050}} >
      <div className="header-top bg-box">
        <div className="container-fluid">
          <div className="row justify-content-between align-items-center">
            <div className="col">
              <div className="logo">
                <a style={{cursor: "pointer"}} onClick={() => navigate("")}>
                  <img
                    src={Logo}
                    alt="Logo"
                    width="180"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
            <div className="col-12 col-md-10 col-lg-8 col-xl-6 col-xxl-5 modal-md">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row g-0">
                  <div className="col pe-2">
                    <div className="form-group mb-0 position-relative has-icon-left">
                      <Input
                        placeholder="Chave de validação"
                        register={register("code")}
                        error={errors?.code?.message}
                      />
                      <div className="form-control-icon">
                        <i className="bi bi-lock"></i>
                      </div>
                    </div>
                  </div>
                  <div className="col col-auto">
                    <button type="submit" className="btn btn-primary">
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {/* {(!!errors && errors.code) && (
              <div className="col">
                <p className="text-danger mb-0">{errors.code?.message}</p>
              </div>
            )} */}
            <DropdownMenu />
          </div>
        </div>
      </div>
    </header>
  );
};
