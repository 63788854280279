import styled from "styled-components";
import Receita from "../../assets/images/bg/receita.jpg";

export const StyledBackground = styled.div`
  background: url(${Receita}) no-repeat;
  background-size: cover;

  img {
    cursor: pointer;
  }
`;
