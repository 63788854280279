import {ReactNode} from "react";

import {AuthProvider} from "./Auth";
import {RegisterProvider} from "./Register";
import {UserProvider} from "./User";

interface ProvidersProps {
  children: ReactNode;
}

const Providers = ({children}: ProvidersProps) => {
  return (
    <AuthProvider>
      <RegisterProvider>
        <UserProvider>
          {children}
        </UserProvider>
      </RegisterProvider>
    </AuthProvider>
  );
};

export default Providers;
