import PharmaceuticalRegisterForm from "../components/PharmaceuticalRegisterForm";
import RegisterContainer from "../components/RegisterContainer";

const PharmaceuticalRegister = () => {
  return (
    <RegisterContainer>
      <h3>Cadastro de Farmacêutico</h3>
      <p className="mb-0">Preencha os campos abaixo para se cadastrar.</p>
      <PharmaceuticalRegisterForm />
    </RegisterContainer>
  );
};

export default PharmaceuticalRegister;
