import { Skeleton } from "@mui/material";
import Box from "@mui/material/Box";

const SearchingValidationCard = () => {
  return (
    <>
      <Box className="card-header bg-secondary">
        <Box className="row align-items-center justify-content-center">
          <Box className="col col-auto">
            <Skeleton
              variant="circular"
              width={60}
              height={60}
              className="display-4"
            />
          </Box>
          <Box className="col-12 col-md-auto text-center text-md-start pt-3 pt-md-0">
            <Skeleton width={150} variant="text" className="text-white mb-0" />
            <Skeleton width={150} variant="text" className="mb-0 text-white" />
          </Box>
        </Box>
      </Box>
      <Box className="card-body p-4">
        <Box className="row justify-content-between">
          <Box className="col-12 col-md-6 col-xxl-auto">
            <Skeleton
              width={150}
              variant="text"
              className="text-muted mb-0 small"
            />
            <Skeleton width={150} variant="text"></Skeleton>
          </Box>
          <Box className="col-12 col-md-6 col-xxl-auto">
            <Skeleton
              width={150}
              variant="text"
              className="text-muted mb-0 small"
            ></Skeleton>
            <Skeleton width={150} variant="text"></Skeleton>
          </Box>
          <Box className="col-12 col-md-6 col-xxl-auto">
            <Skeleton width={150} className="text-muted mb-0 small"></Skeleton>
            <Skeleton width={150} variant="text"></Skeleton>
          </Box>
          <Box className="col-12 col-md-6 col-xxl-auto">
            <Skeleton
              width={150}
              variant="text"
              className="text-muted mb-0 small"
            ></Skeleton>
            <Skeleton width={150} variant="text"></Skeleton>
          </Box>
          <Box className="col-12 pt-3">
            <Skeleton
              width={150}
              variant="text"
              className="text-danger text-center"
            ></Skeleton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const ValidationCardSkeleton = () => {
  return <SearchingValidationCard />;
};

export { ValidationCardSkeleton };
