import {createContext, ReactNode, useContext, useState} from "react";
import {NavigateFunction} from "react-router-dom";
import {PharmaceuticalRegisterData} from "../../types/register";
import {api} from "../../services";
import {toast} from "../../utils/toast";

interface RegisterProviderProps {
  children: ReactNode;
}

interface RegisterProviderData {
  registerType: string;
  isLoading: boolean;

  setRegisterType: React.Dispatch<React.SetStateAction<string>>;

  registerPharmaceutical: (
    data: PharmaceuticalRegisterData,
    navigate: NavigateFunction
  ) => void;
}

export const RegisterContext = createContext<RegisterProviderData>(
  {} as RegisterProviderData
);

export const RegisterProvider = ({children}: RegisterProviderProps) => {
  const [registerType, setRegisterType] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const registerPharmaceutical = async (
    pharmaceuticalRegisterData: PharmaceuticalRegisterData,
    navigate: NavigateFunction
  ) => {
    setIsLoading(true);

    const body: PharmaceuticalRegisterData = {
      ...pharmaceuticalRegisterData
    };

    try {
      await api.post("/User/pharmaceutical/register", body);

      setIsLoading(false);

      toast.fire({
        icon: "success",
        title:
          "Cadastro realizado com sucesso.",
        timer: 8000,
      });

      navigate("/login");
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  return (
    <RegisterContext.Provider
      value={{
        registerType,
        isLoading,
        setRegisterType,
        registerPharmaceutical,
      }}>
      {children}
    </RegisterContext.Provider>
  );
};

export const useRegister = () => useContext(RegisterContext);
