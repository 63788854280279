import PharmaceuticalMyDataForm from "../../MyData/components/PharmaceuticalMyDataForm";
import MyDataContainer from "../components/MyDataContainer";

const PharmaceuticalMyData = () => {
  return (
    <MyDataContainer>
      <PharmaceuticalMyDataForm />
    </MyDataContainer>
  );
};

export default PharmaceuticalMyData;
