import {CardProps} from "../../../types";
import "bootstrap-icons/font/bootstrap-icons.css";
import "moment/locale/pt-br";
import {RecipeQueryResult} from "../../../types/recipe";
import {cnpjMask, cpfMask} from "../../../utils/formMasks";

interface SucessCardProps extends RecipeQueryResult {
  code: string;
}

const ErrorCard = ({code}: CardProps) => {
  return (
    <div className="card-header bg-danger">
      <div className="row align-items-center justify-content-center">
        <div className="col col-auto">
          <i className="bi bi-x-circle text-white display-4"></i>
        </div>
        <div className="col-12 col-md-auto text-center text-md-start pt-3 pt-md-0">
          <p className="text-white mb-0">Receita Digital Não Encontrada</p>
          <h5 className="mb-0 text-white">
            <b>{code}</b>
          </h5>
        </div>
      </div>
    </div>
  );
};

const SucessCard = (props: SucessCardProps) => {
  const {code, patient, local, partner} = props;
  return (
    <>
      <div className="card-header bg-success">
        <div className="row align-items-center justify-content-center">
          <div className="col col-auto">
            <i className="bi bi-shield-check text-white display-4"></i>
          </div>
          <div className="col-12 col-md-auto text-center text-md-start pt-3 pt-md-0">
            <p className="text-white mb-0">Receita Digital Válida</p>
            <h5 className="mb-0 text-white">
              <b>{code}</b>
            </h5>
          </div>
        </div>
      </div>
      <div className="card-body p-4">
        <div className="row justify-content-between">
          <div className="col-12 col-md-6 col-lg-4 col-xxl-auto">
            <p className="text-muted mb-0 small">Parceiro</p>
            <h6>{partner}</h6>
          </div>
          {!patient.name ? (<></>) : (
            <>
              <div className="col-12 col-md-6 col-lg-4 col-xxl-auto">
                <p className="text-muted mb-0 small">Paciente</p>
                <h6>{patient.name}</h6>
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xxl-auto">
                <p className="text-muted mb-0 small">Paciente CPF</p>
                <h6>{cpfMask(patient.cpf)}</h6>
              </div>
            </>
          )}
          {!local.name ? (<></>) : (
            <>
              <div className="col-12 col-md-6 col-lg-4 col-xxl-auto">
                <p className="text-muted mb-0 small">Local</p>
                <h6>{local.name}</h6>
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xxl-auto">
                <p className="text-muted mb-0 small">Local CNPJ</p>
                <h6>{cnpjMask(local.cnpj)}</h6>
              </div>
            </>
          )}
          {/* {medico && (
            <div className="col-12 col-md-6 col-xxl-auto">
              <p className="text-muted mb-0 small">Médico</p>
              <h6>{medico.nome}</h6>
            </div>
          )} */}
          {/* {dataDaConsulta && (
            <div className="col-12 col-md-6 col-xxl-auto">
              <p className="text-muted mb-0 small">Data da Consulta</p>
              <h6>
                {moment(new Date(dataDaConsulta)).locale("pt-br").format("L")} - {moment(new Date(dataDaConsulta)).locale("pt-br").format("LT")}
              </h6>
            </div>
          )} */}
          {/* {tipoDocumento && (
            <div className="col-12 col-md-6 col-xxl-auto">
              <p className="text-muted mb-0 small">Tipo de Documento</p>
              <h6>{tipoDocumento.desc}</h6>
            </div>
          )} */}
          <div className="col-12 pt-3">
            <h3 className="text-danger text-center">Documento de Uso Controlado</h3>
          </div>
        </div>
      </div>
    </>
  );
};

const ValidationCard = (data: SucessCardProps) => {
  const {valid, code} = data;
  return <>{valid ? <SucessCard {...data} /> : <ErrorCard code={code} />}</>;
};

export {ValidationCard};
