import {yupResolver} from "@hookform/resolvers/yup";
import {CircularProgress} from "@mui/material";
import React from "react";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {validateCpf} from "../../../../utils/formValidations";
import {cpfMask} from "../../../../utils/formMasks";
import {useAuth} from "../../../../providers/Auth";
import {IdentificationData, PwdRecoveryStepOneResult} from "../../../../types/auth";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import LinkButton from "../../../../components/LinkButton";
import Swal from "sweetalert2";
import WppAttendance from "../../../../components/WppAttendance";
import {api} from "../../../../services";
import {autoCloseValidationMessage, pwdRecoverySteps2and3} from "../../utils/pwdRecoveryPopUp";
import {useNavigate} from "react-router-dom";

const PasswordRecoveryForm = () => {
  const {isLoading} = useAuth();
  const navigate = useNavigate();

  const schema = yup.object().shape({
    emailOrCpf: yup
      .string()
      .required("*Campo obrigatório")
      .when("isEmail", {
        is: true,
        then: yup.string().email("*Formato incorreto"),
        otherwise: yup
          .string()
          .test("cpfValidator", "*CPF inválido", validateCpf),
      }),
    isEmail: yup.boolean(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<IdentificationData>({resolver: yupResolver(schema)});

  const onSubmit = async (data: IdentificationData) => {
    const {emailOrCpf} = data;

    Swal.showLoading();
    Swal.resetValidationMessage();

    try {
      const response = await api.get(`/Auth/recovery-contacts/${emailOrCpf}`);

      const resultStepOne: PwdRecoveryStepOneResult = {
        request: {emailOrCpf},
        response: response.data,
      };

      Swal.hideLoading();

      // Step 2 - Recovery Code Request and Step 3 - Code Validation
      pwdRecoverySteps2and3(emailOrCpf, <WppAttendance />, resultStepOne);
    } catch (error) {
      Swal.hideLoading();
      autoCloseValidationMessage(
        "CPF/e-mail não encontrado no sistema. Verifique seus dados e tente novamente.",
        7000
      );
    }
  };
  
  const handleUserInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue.match("@")) {
      setValue("isEmail", true);
      setValue("emailOrCpf", inputValue);
    }
    if (!inputValue.match(/([a-z])/gi)) {
      setValue("isEmail", false);
      setValue("emailOrCpf", cpfMask(inputValue));
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="aos-init"
      data-aos="fade-up"
      data-aos-delay="200"
      data-aos-once
    >
      <div className="form-box p-3">
        <div className="row">
          <div className="form-group text-start col-12 mb-3 p-0">
            <label className="form-label">
              E-mail ou CPF
              <span className="text-danger">*</span>
            </label>

            <Input
              placeholder="Digite seu e-mail ou CPF"
              register={register("emailOrCpf")}
              error={errors?.emailOrCpf?.message}
              onChange={handleUserInput}
              type="text"
              autoFocus
            />
          </div>

          <div className="col-12 text-center mb-3">
            <div className="form-text">(*) campos obrigatórios.</div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <div className="row pb-3">
          <div className="col-12 mb-3">
            <Button type="submit" className="btn btn-primary btn-lg w-100" disabled={isLoading}>
              Recuperar Senha
              {!!isLoading && (
                <CircularProgress
                  color="inherit"
                  size={12}
                  className="align-text-top ms-2"
                />
              )}
            </Button>
          </div>

          <div className="col-12 text-center mb-0 d-flex flex-column gap-3 flex-sm-row justify-content-between">
            <LinkButton
              className="fs-6"
              onClick={() => navigate('/register')}
              disabled={isLoading}
            >
              Cadastre-se
            </LinkButton>

            <LinkButton
              className="fs-6"
              onClick={() => navigate('/login')}
              disabled={isLoading}
            >
              Fazer Login
            </LinkButton>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PasswordRecoveryForm;
