interface RecipeInformationProps {
  local: string;
  cnpj: string;
  buyDate: string;
  seller: string;
  pharmaceutical: string;
  CRF: string;
  buyDescription: string;
}

interface ValidationProps {
  code: string;
  validated: boolean;
}

interface CardProps {
  code: string;
}

interface ErrorCardProps {
  title: string;
  text: string;
}

interface PaginationSearchProps {
  search: string;
}

interface Paciente {
  cpf: string;
  nome?: string;
}

interface Medico {
  nome?: string;
  registro: string;
}

interface TipoDocumento {
  codigo: number;
  desc: string;
}

interface Vendedor {
  nome: string;
  registro: string;
  tipo: string;
}

interface Historico {
  local: {
    nome: string;
    cnpj: string;
  };
  dataDaCompra: string;
  vendedor: Vendedor;
  farmaceutico: Vendedor;
  descricaoDaCompra: string;
  medicamentos: {codigo: string | null; descricao: string}[];
}

interface AllInfosInterface {
  valida: boolean;
  paciente: Paciente;
  medico: Medico;
  dataDaConsulta?: string;
  tipoDocumento: TipoDocumento;
  pdf: string;
  historico: Historico[];
}

export class SelectOptionItem {
  key: number | string;
  value: string;

  constructor(key: number | string, value: string) {
    this.key = key;
    this.value = value;
  }
}

export type {RecipeInformationProps, ValidationProps, CardProps, AllInfosInterface, Historico, PaginationSearchProps, ErrorCardProps};
