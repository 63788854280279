import {useState} from "react";
import {Document, Page, pdfjs} from "react-pdf";
import {Modal} from "react-bootstrap";
import {base64ToUint8Array, pdfBase64ToLink} from "../../../utils/fileUtils";

interface PDFProps {
  isOpen: boolean;
  closeModal: () => void;
  code: string;
  pdf: string;
}

function PDF({isOpen, closeModal, code, pdf}: PDFProps) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({numPages}: any) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const onClickPrint = () => {
    const byteArray = base64ToUint8Array(pdf);
    const blob = new Blob([byteArray], {type: 'application/pdf'});
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
  };

  return (
    <>
      <Modal
        size="lg"
        fullscreen={"md-down"}
        show={isOpen}
        onHide={closeModal}
        keyboard={false}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title">Receita Digital: {code}</h5>
          <button
            type="button"
            className="btn btn-sm rounded-pill btn-outline-primary icon"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          >
            <i className="icon-mid bi bi-x"></i>
          </button>
        </div>
        <div id="print-area" className="modal-body p-0 bg-light">
          <Document
            renderMode="svg"
            file={pdfBase64ToLink(pdf)}
            onLoadSuccess={onDocumentLoadSuccess}
            error="Erro ao carregar o PDF"
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div className="buttonc">
              <button
                type="button"
                disabled={pageNumber <= 1}
                onClick={previousPage}
                className="btn btn-sm rounded-pill btn-outline-primary icon"
                style={{marginRight: "10px"}}
              >
                <i className="bi bi-caret-left-fill"></i>
              </button>
              <div className="pagec">
                Página {pageNumber || (numPages ? 1 : "--")} de{" "}
                {numPages || "--"}
              </div>
              <button
                type="button"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
                className="btn btn-sm rounded-pill btn-outline-primary icon"
                style={{marginLeft: "10px"}}
              >
                <i className="bi bi-caret-right-fill"></i>
              </button>
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <a onClick={onClickPrint} target="blank" className="btn btn-dark">
              <i className="bi bi-printer me-2"></i>Imprimir
            </a>
          </div>
        </div>
      </Modal>
    </>
  );
}

export {PDF};
