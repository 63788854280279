import {Dropdown} from "react-bootstrap";
import {NavLink, useNavigate} from "react-router-dom";
import * as C from "./styles";
import {useUser} from "../../../../providers/User";
import './styles.css';

const DropdownMenu = () => {
  const {userData} = useUser();
  const navigate = useNavigate();

  return (
    <C.Container className="col">
      <Dropdown className="bg-transparent b-none align-end">
        <Dropdown.Toggle id="dropdown-basic" className="bg-transparent d-flex dropend show" variant="light" size="sm">
          <div className="user-avatar mr-2"></div>
          <div className="ps-2 text text-start d-none d-md-block">
            <h6 className="user-dropdown-name">{userData?.name}</h6>
            <p className="user-dropdown-status text-sm text-muted">Conectadoc</p>
          </div>
          <i className="bi bi-chevron-down ms-2 align-self-center"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu id="dropdown-menu">
          <Dropdown.Header className="lh-1 dropdown-header">
            <h6>Olá, {userData?.name}!</h6>
          </Dropdown.Header>

          <NavLink className="dropdown-item" to="/meus-dados">
            <i className="icon-mid bi bi-person me-2" />
            Meus Dados
          </NavLink>

          <Dropdown.Item className="dropdown-item" onClick={() => navigate('/validated-recipes', {state: {backgroundNotImage: true}})}>
            <i className="icon-mid bi bi-clipboard2-pulse me-2" />
            Receitas Validadas
          </Dropdown.Item>

          <Dropdown.Divider />

          <NavLink className="dropdown-item" to="/logout">
            <i className="icon-mid bi bi-box-arrow-left me-2" />
            Sair
          </NavLink>
        </Dropdown.Menu>
      </Dropdown>
    </C.Container>
  );
};

export default DropdownMenu;
