import axios, { AxiosRequestConfig } from "axios";

import {sessStorage} from "../utils/storage";
import {API_URL} from "../config";

export const api = axios.create({
  baseURL: API_URL,
});

const authRequestInterceptor = (config: AxiosRequestConfig) => {
  const token = sessStorage.getToken();

  if (!config.headers) {
    config.headers = {};
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.headers.Accept = "application/json";
  return config;
};

api.interceptors.request.use(authRequestInterceptor);
