const NotFound = () => {
    return (
        <div className="content container flex-grow-1 d-flex flex-column">
            <div className="card-header bg-danger">
                <div className="row align-items-center justify-content-center">
                    <div className="col col-auto">
                        <i className="bi bi-x-circle text-white display-4"></i>
                    </div>
                    <div className="col-12 col-md-auto text-center text-md-start pt-3 pt-md-0">
                        <p className="text-white mb-0">404 - Página Não Encontrada</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
