import { Skeleton } from "@mui/material";
import Box from "@mui/material/Box";

export const InfoCardSkeleton = () => {
  return (
    <Box className="row mt-4 pb-4 justify-content-between border-bottom">
      <Box className="col-12 col-md-8">
        <Skeleton variant="text" className="text-muted mb-0 small" />
        <Skeleton variant="text" />
      </Box>
      <Box className="col-12 col-md-4">
        <Skeleton variant="text" className="text-muted mb-0 small" />
        <Skeleton variant="text" />
      </Box>
      <Box className="col-12 col-md-6 col-xxl-4">
        <Skeleton variant="text" className="text-muted mb-0 small" />
        <Skeleton variant="text" />
      </Box>
      <Box className="col-12 col-md-6 col-xxl-4">
        <Skeleton variant="text" className="text-muted mb-0 small" />
        <Skeleton variant="text" />
      </Box>
      <Box className="col-12 col-md-6 col-xxl-4">
        <Box className="row">
          <Box className="col col-auto">
            <Skeleton width={50} variant="text" className="text-muted mb-0 small" />
            <Skeleton width={50} variant="text" />
          </Box>
          <Box className="col col-auto">
            <Skeleton width={50} variant="text" className="text-muted mb-0 small" />
            <Skeleton width={50} variant="text" />
          </Box>
        </Box>
      </Box>
      <Box className="col-12">
        <Skeleton variant="text" className="text-muted mb-0 small" />
        <Skeleton variant="text" />
      </Box>
    </Box>
  );
};
