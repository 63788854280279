import Router from "./routes";
import 'bootstrap-icons/font/bootstrap-icons.css';

function App() {
  return (
    <>
      <Router />
    </>
  );
}

export default App;
