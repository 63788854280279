import Swal from "sweetalert2";
import withReactContent, { ReactElementOr } from "sweetalert2-react-content";


import CodeValidationForm from "../../../components/CodeValidationForm";
import IdentificationForm from "../../../components/IdentificationForm";
import "./styles.css";
import "animate.css";
import NewPasswordForm from "../../../components/NewPwdForm";
import {PwdRecoveryStepOneResult} from "../../../types/auth";
import {api} from "../../../services";

export const pwdRecoveryPopUp = withReactContent(
  Swal.mixin({
    progressSteps: ["1", "2", "3", "4"],
    showCloseButton: true,
    showConfirmButton: false,
    focusConfirm: false,
    title: "Receita Digital",
    customClass: {
      title: "pt-5 text-primary",
      validationMessage: "animate__animated animate__fadeInDown px-4",
    },
    showClass: {
      popup: "animate__animated animate__fadeInUp",
    },
    hideClass: {
      popup: "animate__animated animate__fadeOutUp",
    },
  })
);

export const pwdRecoveryStep1 = () => {
  return pwdRecoveryPopUp.fire({
    currentProgressStep: 0,
    html: <IdentificationForm />,
  });
};

export const pwdRecoverySteps2and3 = (
  emailOrCpf: string,
  footerComponent: ReactElementOr<"html">,
  resultStepOne: PwdRecoveryStepOneResult
) => {
  return pwdRecoveryPopUp.fire({
    currentProgressStep: 1,
    showConfirmButton: true,
    text: "Em qual desses contatos você gostaria de receber o código de recuperação?",
    input: "radio",
    inputOptions: {
      Email: `${resultStepOne.response.email} (e-mail)`,
      WhatsApp: `${resultStepOne.response.phone} (WhatsApp)`,
      Sms: `${resultStepOne.response.phone} (SMS)`,
    },
    confirmButtonText: "Solicitar código de recuperação",
    showLoaderOnConfirm: true,
    customClass: {
      title: "pt-5 text-primary",
      input: "flex-column gap-2 align-items-start",
      confirmButton: "btn btn-primary",
      footer: "flex-column mx-5",
    },
    buttonsStyling: false,
    footer: footerComponent,
    preConfirm: async (inputOption: string) => {
      if (!inputOption) {
        return autoCloseValidationMessage("Escolha uma das opções", 4000);
      }

      try {
        await api.post(`/Auth/recoveryBy${inputOption}`, resultStepOne.request);

        // Step 3 - Code Validation
        pwdRecoveryStep3(
          <CodeValidationForm
            emailOrCpf={emailOrCpf}
            inputOption={inputOption}
            resultStepOne={resultStepOne}
          />
        );
      } catch (error: any) {
        let message = "";
        if (error.response.status === 400) {
          message = (Object.values(error.response.data)[0] as string[])[0];
        } else {
          message = "Ocorreu um erro. Por favor, tente mais tarde.";
        }

        autoCloseValidationMessage(message, 4000);
      }
    },
  });
};

const pwdRecoveryStep3 = (mainComponent: ReactElementOr<"html">) => {
  return pwdRecoveryPopUp.fire({
    currentProgressStep: 2,
    html: mainComponent,
  });
};

export const pwdRecoveryStep4 = (code: string, emailOrCpf: string) => {
  return pwdRecoveryPopUp.fire({
    currentProgressStep: 3,
    html: <NewPasswordForm code={code} emailOrCpf={emailOrCpf} />,
  });
};

export const autoCloseValidationMessage = (
  message: string,
  duration: number
) => {
  Swal.showValidationMessage(message);
  setTimeout(() => {
    Swal.resetValidationMessage();
  }, duration);
};
