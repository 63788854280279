import {Itens} from "../Modals/RegisterBuy/types";

interface ItemCardInterface {
  itensList: Itens[];
  item: Itens;
  setItensList: (itens: Itens[]) => void;
}

const ItemCard = ({itensList, item, setItensList}: ItemCardInterface) => {
  return (
    <li className="list-group-item">
      <div className="row align-items-center">
        <div className="col">{item.nome}</div>
        <div className="col col-auto">
          <button
            type="button"
            onClick={() => {
              const newItens = itensList.filter((el) => el.id !== item.id);

              setItensList(newItens);
            }}
            className="btn btn-sm btn-link"
          >
            <i className="bi bi-x text-danger"></i>
          </button>
        </div>
      </div>
    </li>
  );
};

export {ItemCard};
