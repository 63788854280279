import styled from "styled-components";

interface InputContainerProps {
  isPasswordInput?: boolean;
  hasError?: boolean;
}

export const InputContainer = styled.div<InputContainerProps>`
  input {
    padding-right: ${(props) => (props.isPasswordInput ? "3rem" : "1rem")};
    border-color: ${(props) => props.hasError && "var(--danger)"};
    caret-color: var(--primary);

    :focus + div {
      color: var(--primary);
    }

    :not(:focus) + div {
      color: ${(props) => props.hasError && "var(--bs-danger)"};
    }

    ::placeholder {
      text-transform: none;
    }
  }
`;

export const PasswordIcon = styled.div`
  cursor: pointer;
`;

export const ErrorContainer = styled.div`
  height: 1rem;
`;
