/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {useEffect, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import Swal from "sweetalert2";
import {InfoCard} from "../../components/Cards/InfoCard";
import {ValidationCard} from "../../components/Cards/ValidationCards";
import {PDF} from "../../components/Modals/PDFModal";
import {RegisterBuy} from "../../components/Modals/RegisterBuy";
import {InfoCardSkeleton} from "../../components/Skeletons/infoCardSkeleton";
import {api} from "../../lib/api";
import ValidationPageLoading from "./components/ValidationPageLoading";
import {RecipeQueryResult} from "../../types/recipe";
import {ErrorCard} from "../../components/Cards/ErrorCard";

const ValidationPage = () => {
  const {code} = useParams();
  const [searchParams, setSearchParams] = useSearchParams();  

  const [loading, setLoading] = useState<boolean>(true);
  const [loadSkeleton, setLoadSkeleton] = useState<boolean>(true);
  const [infos, setInfos] = useState<RecipeQueryResult>();
  const [modal, setModal] = useState<boolean>(false);
  const [pdfModal, setPdfModal] = useState<boolean>(false);

  async function getData() {
    try {
      setLoading(true);
      setLoadSkeleton(true);

      const {data} = (await api.get(`/receita/${code}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })) as {
        data: RecipeQueryResult;
      };

      if (!data) {
        setTimeout(() => {
          setLoading(false);
          setLoadSkeleton(false);
        }, 1500);

        return setInfos(undefined);
      }

      setInfos(data);

      setTimeout(() => {
        setLoading(false);
        setLoadSkeleton(false);
      }, 1500);
    } catch (error) {
      setInfos(undefined);

      setLoading(false);
      setLoadSkeleton(false);
    }
  }

  const closeModal = () => {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Você não vai conseguir desfazer essa ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sim, cancelar!",
      cancelButtonText: "Continuar",
    }).then((result) => {
      if (result.isConfirmed) {
        setModal(false);
        setPdfModal(false);
      }
    });
  };

  const closeModalWithoutWarning = () => {
    setModal(false);
    setPdfModal(false);
  };

  const closeModalAndResetHistory = () => {
    setLoadSkeleton(true);

    getData();

    setModal(false);

    setTimeout(() => {
      setLoadSkeleton(false);
    }, 1500);
  };

  useEffect(() => {
    getData();
  }, [searchParams.toString()]);

  return (
    <>
      <div className="container">
        <>
          {loading ? (
            <ValidationPageLoading />
          ) : (
            <>
              {!infos && (<ErrorCard title="Receita Digital Não Encontrada." text={code ?? ""} />)}
              {infos && (
                <>
                  <div className="card mb-0 sticky-xl-top">{infos && code && <ValidationCard code={code} {...infos} />}</div>
                  {infos.valid && (
                    <div className="card-footer text-center pb-0">
                      {infos.base64PDFFile && infos.base64PDFFile.length > 0 && (
                        <button onClick={() => setPdfModal(true)} className="btn mb-4 mx-1 btn-outline-dark">
                          <i className="bi bi-file-pdf me-2"></i>Ver Receita
                        </button>
                      )}

                      <button onClick={() => setModal(true)} className="btn mb-4 mx-1 btn-success">
                        <i className="bi bi-cart-check me-2"></i>Registrar Compra
                      </button>
                    </div>
                  )}

                  {infos && infos.valid && infos.dispensations && (
                    <div
                      className="card border border-top-0 bg-light mx-3 mx-md-5"
                      style={{
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: "0",
                      }}
                    >
                      {infos.dispensations.length > 0 ? (
                        <>
                          <div className="card-body p-4">
                            <h3 className="mb-5">Informações</h3>
                            {infos.dispensations.map((elm, i) => (loadSkeleton ? <>{i < 2 && <InfoCardSkeleton key={i} />}</> : <InfoCard key={i} {...elm} />))}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="card-body p-4">
                            <h3 className="mb-5">Informações</h3>
                            <div className="row mt-4 pb-4 justify-content-between border-bottom">
                              <h3 className="text-center" style={{color: "#4e5f8c"}}>
                                Receita sem registro de uso
                              </h3>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      </div>
      {infos && pdfModal && (
        <div className="modal-content">
          <PDF pdf={infos.base64PDFFile} code={code ? code : ""} isOpen={pdfModal} closeModal={closeModalWithoutWarning} />
        </div>
      )}
      {modal && code && <RegisterBuy code={code} isOpen={modal} closeModalWithoutWarning={closeModalAndResetHistory} closeModal={closeModal} />}
    </>
  );
};

export default ValidationPage;
